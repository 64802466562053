<template>
  <loading
    :active="isLoading"
    :is-full-page="fullPage"
    :z-index="1100"
  ></loading>
  <Form
    v-slot="{ validate, values }"
    @submit="onSubmit"
    :validation-schema="schema"
    @invalid-submit="onInvalidSubmit"
  >
    <div class="accordion" id="accordionAnagraficaSocieta">
      <form autocomplete="new-off">
        <div class="card mb-7">
          <div class="accordion-item">
            <h6
              class="accordion-header"
              id="accordionAnagraficaSocieta-headingOne"
            >
              <button
                class="accordion-button fw-bolder m-0 p-6"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#accordionAnagraficaSocieta-collapseOne"
                aria-expanded="true"
                aria-controls="accordionAnagraficaSocieta-collapseOne"
              >
                Dati Societari
              </button>
            </h6>
            <div
              id="accordionAnagraficaSocieta-collapseOne"
              class="accordion-collapse collapse show"
              aria-labelledby="accordionAnagraficaSocieta-headingOne"
            >
              <div class="accordion-body p-0 m-0">
                <!--begin::Card body-->
                <div class="card-body p-6">
                  <div class="ps-5 pe-5">
                    <div class="row">
                      <div class="mb-4 col-md-6">
                        <label class="fw-bold text-gray-600"
                          >Denominazione*</label
                        >
                        <TextInput name="denominazione" type="text" />
                      </div>
                      <div class="mb-4 col-md-3">
                        <label class="fw-bold text-gray-600"
                          >Comitato affiliazione*</label
                        >

                        <SelectInput
                          :options="comitati"
                          name="id_periferico"
                          @changeSelect="getCodAffSugg(values.id_periferico)"
                        />
                      </div>
                      <div class="mb-4 col-md-3">
                        <label class="fw-bold text-gray-600"
                          >Codice Affiliazione*
                        </label>
                        <Field
                          name="cod_affiliazione"
                          type="text"
                          v-model="CodAff"
                          class="form-control"
                          :disabled="!values.id_periferico || loadingCodice"
                          @input="
                            msgErrore = '';
                            suggestion == CodAff
                              ? (presente = true)
                              : (presente = false);
                          "
                        />
                        <div
                          v-if="msgErrore != ''"
                          class="help-message"
                          style="color: red"
                        >
                          {{ msgErrore }}
                        </div>
                        <ErrorMessage
                          v-else
                          class="help-message"
                          style="color: red"
                          name="cod_affiliazione"
                        />
                      </div>
                    </div>

                    <div class="row">
                      <div class="mb-4 col-md-12">
                        <label class="fw-bold text-gray-600"
                          >Descrizione breve</label
                        >
                        <TextInput name="sigla" type="text" />
                      </div>
                    </div>
                    <div class="row">
                      <div class="mb-4 col-md-3">
                        <label class="fw-bold text-gray-600"
                          >Data costituzione</label
                        >

                        <Datepicker
                          v-model="data_costituzione"
                          name="data_costituzione"
                          :format="format"
                          :monthChangeOnScroll="false"
                          :enableTimePicker="false"
                          inputClassName="h-28"
                          autoApply
                        />
                        <Field
                          name="data_costituzione"
                          type="hidden"
                          class="form-control"
                          v-model="data_costituzione"
                        />
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="data_costituzione"
                        />
                      </div>
                      <div class="mb-4 col-md-3">
                        <label class="fw-bold text-gray-600"
                          >Data prima affiliazione</label
                        >
                        <Datepicker
                          v-model="data_prima_aff"
                          :format="format"
                          :monthChangeOnScroll="false"
                          :enableTimePicker="false"
                          inputClassName="h-28"
                          autoApply
                        />
                        <Field
                          name="data_prima_aff"
                          type="hidden"
                          class="form-control"
                          v-model="data_prima_aff"
                        />
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="data_prima_aff"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="mb-4 col-md-4">
                        <label class="fw-bold text-gray-600"
                          >Rappresentante legale*</label
                        >
                        <div class="dp__input_wrap text-gray-600">
                          <div>
                            <Field
                              name="nome_rl"
                              type="text"
                              class="form-control"
                              @input="getPersone($event.target.value)"
                              v-model="nome_rl"
                              autocomplete="new-off"
                            />
                            <svg
                              @click="
                                resetLegali();
                                id_resplegale = null;
                                nome_rl = null;
                              "
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                              width="32"
                              height="32"
                              viewBox="0 0 32 32"
                              class="dp__icon dp__clear_icon dp__input_icons"
                            >
                              <path
                                d="M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
                              ></path>
                              <path
                                d="M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
                              ></path>
                            </svg>
                            <Field
                              name="id_resplegale"
                              type="hidden"
                              class="form-control"
                              v-model="id_resplegale"
                            />
                          </div>
                          <ErrorMessage
                            class="help-message"
                            style="color: red"
                            name="id_resplegale"
                          />
                        </div>
                        <ListPersone
                          :list="legali"
                          @hideList="resetLegali"
                          @selectedLegale="
                            resetLegali();
                            nome_rl = $event.name;
                            id_resplegale = $event.value;
                          "
                          :focusOnInput="true"
                        ></ListPersone>
                      </div>
                      <div class="mb-4 col-md-4">
                        <label class="fw-bold text-gray-600"
                          >Natura giuridica*</label
                        >
                        <SelectInput :options="societaTipo" name="id_tipo" />
                      </div>
                      <div class="mb-4 col-md-3">
                        <label class="fw-bold text-gray-600"
                          >Polisportiva</label
                        >
                        <SelectInput
                          :options="polisportiva"
                          name="polisportiva"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="mb-4 col-md-4">
                        <label class="fw-bold text-gray-600"
                          >Codice fiscale*</label
                        >

                        <Field
                          name="cod_fisc"
                          type="text"
                          class="form-control"
                        />
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="cod_fisc"
                        />
                      </div>
                      <div class="mb-4 col-md-4">
                        <label class="fw-bold text-gray-600">Partita IVA</label>

                        <TextInput name="cod_iva" type="text" />
                      </div>
                    </div>
                    <div class="row">
                      <div class="mb-4 col-md-4">
                        <label class="fw-bold text-gray-600">PEC*</label>

                        <TextInput name="email_pec" type="email" />
                      </div>
                      <div class="mb-4 col-md-4">
                        <label class="fw-bold text-gray-600">E-mail*</label>
                        <Field name="email" type="text" class="form-control" />
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="email"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="mb-4 col-md-4">
                        <label class="fw-bold text-gray-600">Sito web</label>

                        <TextInput name="web" type="text" />
                      </div>
                      <div class="mb-4 col-md-4">
                        <label class="fw-bold text-gray-600">Skype</label>
                        <Field name="skype" type="text" class="form-control" />
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="skype"
                        />
                      </div>
                      <div class="mb-4 col-md-4">
                        <label class="fw-bold text-gray-600">Facebook</label>

                        <TextInput name="facebook" type="text" />
                      </div>
                    </div>
                  </div>
                </div>
                <!--end::Card body-->
              </div>
            </div>
          </div>
        </div>

        <div class="card mb-7">
          <div class="accordion-item">
            <h6
              class="accordion-header"
              id="accordionAnagraficaSocieta-headingTwo"
            >
              <button
                class="accordion-button fw-bolder m-0 p-6"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#accordionAnagraficaSocieta-collapseTwo"
                aria-expanded="true"
                aria-controls="accordionAnagraficaSocieta-collapseTwo"
              >
                Sede legale
              </button>
            </h6>
            <div
              id="accordionAnagraficaSocieta-collapseTwo"
              class="accordion-collapse collapse show"
              aria-labelledby="accordionAnagraficaSocieta-headingTwo"
            >
              <div class="accordion-body p-0 m-0">
                <!--begin::Card body-->
                <div class="card-body p-6">
                  <div class="ps-5 pe-5">
                    <div class="row">
                      <div class="mb-4 col-md-4">
                        <label class="fw-bold text-gray-600">Nazione*</label>
                        <Field
                          name="nazione_legale"
                          as="select"
                          class="form-select"
                          v-model="nazione_legale"
                          @change="
                            changeselectedItaliaL($event.target.value);
                            validate();
                            id_provincia_legale = 0;
                            comune_legale_txt = '';
                            id_comune_legale = 0;
                          "
                        >
                          <option :value="null">-</option>
                          <option
                            v-for="nazione in nazioni"
                            :value="nazione.id"
                            :key="nazione"
                          >
                            {{ nazione.label }}
                          </option>
                        </Field>
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="nazione_legale"
                        />
                      </div>
                      <div class="mb-4 col-md-4">
                        <label class="fw-bold text-gray-600">Provincia*</label>
                        <Field
                          name="id_provincia"
                          as="select"
                          class="form-select"
                          v-model="id_provincia_legale"
                          @change="
                            changeselectedProvinciaL($event.target.value);
                            validate();
                            comune_legale_txt = '';
                            id_comune_legale = 0;
                          "
                          :disabled="selectedItaliaL ? false : true"
                        >
                          <option :value="null">-</option>
                          <option
                            v-for="provincia in province"
                            :value="provincia.codP"
                            :key="provincia.codP"
                          >
                            {{ provincia.nomeP }}
                          </option>
                        </Field>
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="id_provincia"
                        />
                      </div>
                      <div class="mb-4 col-md-4">
                        <label class="fw-bold text-gray-600">Comune*</label>

                        <Field
                          name="comune_legale_txt"
                          type="text"
                          class="form-control"
                          @input="
                            spliceComuniIstat();
                            setComuni(comune_legale_txt, id_provincia_legale);
                          "
                          @focus="inputFocus = 'comuneL'"
                          @keyup.delete="
                            spliceComuniIstat();
                            id_comune_legale = null;
                          "
                          v-model="comune_legale_txt"
                          :disabled="
                            selectedProvinciaL && selectedItaliaL ? false : true
                          "
                          autocomplete="new-off"
                        />
                        <Field
                          name="id_comune"
                          type="hidden"
                          v-model="id_comune_legale"
                          class="form-control"
                        />
                        <ListComuni
                          v-if="comuniIstat && inputFocus === 'comuneL'"
                          :list="comuniIstat"
                          @hideList="spliceComuniIstat"
                          @selectedElement="
                            spliceComuniIstat();
                            id_comune_legale = $event.value;
                            comune_legale_txt = $event.name;
                          "
                        ></ListComuni>
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="id_comune"
                        />
                      </div>
                    </div>

                    <div class="row">
                      <div class="mb-4 col-md-5">
                        <label class="fw-bold text-gray-600">Indirizzo*</label>
                        <TextInput name="indirizzo" type="text" />
                      </div>
                      <div class="mb-4 col-md-3">
                        <label class="fw-bold text-gray-600">C.A.P.*</label>
                        <TextInput name="cap" type="text" />
                      </div>
                    </div>
                    <div class="row">
                      <div class="mb-4 col-md-5">
                        <label class="fw-bold text-gray-600">Presso</label>
                        <TextInput name="presso" type="text" />
                      </div>
                      <div class="mb-4 col-md-5">
                        <label class="fw-bold text-gray-600">Località</label>
                        <TextInput name="localita" type="text" />
                      </div>
                    </div>
                    <div class="row">
                      <div class="mb-2 col-md-3">
                        <label class="fw-bold text-gray-600">Cellulare</label>
                        <Field name="mobile" type="text" class="form-control" />
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="mobile"
                        />
                      </div>
                      <div class="mb-2 col-md-3">
                        <label class="fw-bold text-gray-600">Telefono 1*</label>
                        <Field
                          name="telefono"
                          type="text"
                          class="form-control"
                        />
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="telefono"
                        />
                      </div>
                      <div class="mb-2 col-md-3">
                        <label class="fw-bold text-gray-600">Telefono 2</label>

                        <!-- <TextInput name="telefono2" type="text" />  da rivedere errore 2 elementi nel DOM-->
                        <Field
                          name="telefono2"
                          type="text"
                          class="form-control"
                        />
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="telefono2"
                        />
                      </div>
                      <div class="mb-2 col-md-3">
                        <label class="fw-bold text-gray-600">Fax</label>
                        <Field name="fax" type="text" class="form-control" />
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="fax"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!--end::Card body-->
              </div>
            </div>
          </div>
        </div>

        <div class="card mb-7">
          <div class="accordion-item">
            <h6
              class="accordion-header"
              id="accordionAnagraficaSocieta-headingThree"
            >
              <button
                class="accordion-button fw-bolder m-0 p-6"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#accordionAnagraficaSocieta-collapseThree"
                aria-expanded="true"
                aria-controls="accordionAnagraficaSocieta-collapseThree"
              >
                Recapito postale
              </button>
            </h6>
            <div
              id="accordionAnagraficaSocieta-collapseThree"
              class="accordion-collapse collapse show"
              aria-labelledby="accordionAnagraficaSocieta-headingThree"
            >
              <div class="accordion-body p-0 m-0">
                <!--begin::Card body-->
                <div class="card-body p-6">
                  <div class="ps-5 pe-5">
                    <div class="row">
                      <div class="mb-4 col-md-4">
                        <label class="fw-bold text-gray-600"
                          >Nazione postale*</label
                        >

                        <Field
                          name="nazione_postale"
                          as="select"
                          class="form-select"
                          v-model="nazione_postale"
                          @change="
                            changeselectedItaliaP($event.target.value);
                            validate();
                            id_provincia_postale = 0;
                            comune_postale_txt = '';
                            id_comune_postale = 0;
                          "
                        >
                          <option :value="null">-</option>
                          <option
                            v-for="nazione in nazioni"
                            :value="nazione.id"
                            :key="nazione"
                          >
                            {{ nazione.label }}
                          </option>
                        </Field>
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="nazione_postale"
                        />
                      </div>
                      <div class="mb-4 col-md-4">
                        <label class="fw-bold text-gray-600"
                          >Provincia postale</label
                        >

                        <Field
                          name="id_provincia_postale"
                          as="select"
                          class="form-select"
                          v-model="id_provincia_postale"
                          @change="
                            changeselectedProvinciaP($event.target.value);
                            validate();
                            comune_postale_txt = '';
                            id_comune_postale = 0;
                          "
                          :disabled="selectedItaliaP ? false : true"
                        >
                          <option :value="null">-</option>
                          <option
                            v-for="provincia in province"
                            :value="provincia.codP"
                            :key="provincia.codP"
                          >
                            {{ provincia.nomeP }}
                          </option>
                        </Field>
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="id_provincia_postale"
                        />
                      </div>
                      <div class="mb-4 col-md-4">
                        <label class="fw-bold text-gray-600"
                          >Comune postale</label
                        >
                        <Field
                          name="comune_postale_txt"
                          type="text"
                          class="form-control"
                          @input="
                            spliceComuniIstat();
                            setComuni(comune_postale_txt, id_provincia_postale);
                          "
                          @focus="inputFocus = 'comuneP'"
                          @keyup.delete="
                            spliceComuniIstat();
                            id_comune_postale = null;
                          "
                          v-model="comune_postale_txt"
                          :disabled="
                            selectedProvinciaP && selectedItaliaP ? false : true
                          "
                          autocomplete="new-off"
                        />
                        <Field
                          name="id_comune_postale"
                          type="hidden"
                          v-model="id_comune_postale"
                          class="form-control"
                        />
                        <ListComuni
                          v-if="comuniIstat && inputFocus === 'comuneP'"
                          :list="comuniIstat"
                          @hideList="spliceComuniIstat"
                          @selectedElement="
                            spliceComuniIstat();
                            id_comune_postale = $event.value;
                            comune_postale_txt = $event.name;
                          "
                        ></ListComuni>
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="id_comune_postale"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="mb-4 col-md-5">
                        <label class="fw-bold text-gray-600">Indirizzo*</label>
                        <TextInput name="indirizzo_postale" type="text" />
                      </div>
                      <div class="mb-4 col-md-3">
                        <label class="fw-bold text-gray-600">C.A.P.*</label>
                        <TextInput name="cap_postale" type="text" />
                      </div>
                    </div>
                    <div class="row">
                      <div class="mb-2 col-md-5">
                        <label class="fw-bold text-gray-600">Presso</label>
                        <TextInput name="nome_postale" type="text" />
                      </div>
                      <div class="mb-2 col-md-5">
                        <label class="fw-bold text-gray-600">Località</label>
                        <TextInput name="localita_postale" type="text" />
                      </div>
                    </div>
                  </div>
                </div>
                <!--end::Card body-->
              </div>
            </div>
          </div>
        </div>

        <div class="card mb-7">
          <div class="accordion-item">
            <h6
              class="accordion-header"
              id="accordionAnagraficaSocieta-headingFour"
            >
              <button
                class="accordion-button fw-bolder m-0 p-6"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#accordionAnagraficaSocieta-collapseFour"
                aria-expanded="true"
                aria-controls="accordionAnagraficaSocieta-collapseFour"
              >
                Recapito fiscale
              </button>
            </h6>
            <div
              id="accordionAnagraficaSocieta-collapseFour"
              class="accordion-collapse collapse show"
              aria-labelledby="accordionAnagraficaSocieta-headingFour"
            >
              <div class="accordion-body p-0 m-0">
                <!--begin::Card body-->
                <div class="card-body p-6">
                  <div class="ps-5 pe-5">
                    <div class="row">
                      <div class="mb-4 col-md-4">
                        <label class="fw-bold text-gray-600">Nazione</label>
                        <!-- setta provincia e comune a 0 -->
                        <Field
                          name="nazione_fiscale"
                          as="select"
                          class="form-select"
                          v-model="nazione_fiscale"
                          @change="
                            changeselectedItaliaF($event.target.value);
                            id_provincia_fiscale = 0;
                            comune_fiscale_txt = '';
                            id_comune_fiscale = 0;
                          "
                        >
                          <option :value="null">-</option>
                          <option
                            v-for="nazione in nazioni"
                            :value="nazione.id"
                            :key="nazione"
                          >
                            {{ nazione.label }}
                          </option>
                        </Field>
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="nazione_fiscale"
                        />
                      </div>
                      <div class="mb-4 col-md-4">
                        <label class="fw-bold text-gray-600">Provincia</label>

                        <Field
                          name="id_provincia_fiscale"
                          as="select"
                          class="form-select"
                          v-model="id_provincia_fiscale"
                          @change="
                            changeselectedProvinciaF($event.target.value);
                            comune_fiscale_txt = '';
                            id_comune_fiscale = 0;
                          "
                          :disabled="selectedItaliaF ? false : true"
                        >
                          <option :value="null">-</option>
                          <option
                            v-for="provincia in province"
                            :value="provincia.codP"
                            :key="provincia.codP"
                          >
                            {{ provincia.nomeP }}
                          </option>
                        </Field>
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="id_provincia_fiscale"
                        />
                      </div>
                      <div class="mb-4 col-md-4">
                        <label class="fw-bold text-gray-600">Comune</label>
                        <Field
                          name="comune_fiscale_txt"
                          type="text"
                          class="form-control"
                          @input="
                            spliceComuniIstat();
                            setComuni(comune_fiscale_txt, id_provincia_fiscale);
                          "
                          @focus="inputFocus = 'comuneF'"
                          @keyup.delete="spliceComuniIstat()"
                          v-model="comune_fiscale_txt"
                          :disabled="
                            selectedProvinciaF && selectedItaliaF ? false : true
                          "
                          autocomplete="new-off"
                        />
                        <Field
                          name="id_comune_fiscale"
                          type="hidden"
                          v-model="id_comune_fiscale"
                          class="form-control"
                        />
                        <ListComuni
                          v-if="comuniIstat && inputFocus === 'comuneF'"
                          :list="comuniIstat"
                          @hideList="spliceComuniIstat"
                          @selectedElement="
                            spliceComuniIstat();
                            id_comune_fiscale = $event.value;
                            comune_fiscale_txt = $event.name;
                          "
                        ></ListComuni>
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="id_comune_fiscale"
                        />
                      </div>
                    </div>

                    <div class="row">
                      <div class="mb-4 col-md-5">
                        <label class="fw-bold text-gray-600">Indirizzo</label>
                        <TextInput name="indirizzo_fiscale" type="text" />
                      </div>
                      <div class="mb-4 col-md-3">
                        <label class="fw-bold text-gray-600">C.A.P.</label>
                        <TextInput name="cap_fiscale" type="text" />
                      </div>
                    </div>
                    <div class="row">
                      <div class="mb-2 col-md-5">
                        <label class="fw-bold text-gray-600">Presso</label>
                        <TextInput name="presso_fiscale" type="text" />
                      </div>
                      <div class="mb-2 col-md-5">
                        <label class="fw-bold text-gray-600">Località</label>
                        <TextInput name="localita_fiscale" type="text" />
                      </div>
                    </div>
                  </div>
                </div>
                <!--end::Card body-->
              </div>
            </div>
          </div>
        </div>

        <div class="card mb-7">
          <div class="accordion-item">
            <h6
              class="accordion-header"
              id="accordionAnagraficaSocieta-headingFour"
            >
              <button
                class="accordion-button fw-bolder m-0 p-6"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#accordionAnagraficaSocieta-collapseFive"
                aria-expanded="true"
                aria-controls="accordionAnagraficaSocieta-collapseFive"
              >
                Coordinate bancarie
              </button>
            </h6>
            <div
              id="accordionAnagraficaSocieta-collapseFive"
              class="accordion-collapse collapse show"
              aria-labelledby="accordionAnagraficaSocieta-headingFive"
            >
              <div class="accordion-body p-0 m-0">
                <!--begin::Card body-->
                <div class="card-body p-6">
                  <div class="ps-5 pe-5">
                    <div class="row">
                      <div class="mb-2 col-md-4">
                        <label class="fw-bold text-gray-600">Banca</label>
                        <Field name="banca" type="text" class="form-control" />
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="banca"
                        />
                      </div>
                      <div class="mb-2 col-md-4">
                        <label class="fw-bold text-gray-600">Agenzia</label>
                        <Field
                          name="indirizzo_banca"
                          type="text"
                          class="form-control"
                        />
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="indirizzo_banca"
                        />
                      </div>
                      <div class="mb-2 col-md-4">
                        <label class="fw-bold text-gray-600">IBAN*</label>
                        <Field name="iban" type="text" class="form-control" />
                        <ErrorMessage
                          class="help-message"
                          style="color: red"
                          name="iban"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!--end::Card body-->
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="card mb-7">
        <div class="accordion-item">
          <h6
            class="accordion-header"
            id="accordionAnagraficaSocieta-headingFour"
          >
            <button
              class="accordion-button fw-bolder m-0 p-6"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#accordionAnagraficaSocieta-collapseSix"
              aria-expanded="true"
              aria-controls="accordionAnagraficaSocieta-collapseSix"
            >
              Credenziali di accesso
            </button>
          </h6>
          <div
            id="accordionAnagraficaSocieta-collapseSix"
            class="accordion-collapse collapse show"
            aria-labelledby="accordionAnagraficaSocieta-headingSix"
          >
            <div class="accordion-body p-0 m-0">

              <div class="card-body p-6">
                <div class="ps-5 pe-5">
                  <div class="row">
                    <div class="col-md-4 mb-2">
                      <label class="fw-bold text-gray-600">User*</label>

                      <TextInput name="username" type="text" />
                    </div>
                    <div class="col-md-4 mb-2">
                      <label class="fw-bold text-gray-600">Password*</label>
                      <TextInput name="pasw_script" type="password" />
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div> -->
      </form>
    </div>

    <!--end::details View-->
    <div class="text-end">
      <button class="badge bg-secondary rounded blueFit fs-5">
        Salva Anagrafica
      </button>
    </div>
    <ModalAddPersona :fromAddSocieta="true" />
  </Form>
</template>

<script>
import {
  defineComponent,
  onMounted,
  computed,
  ref,
  getCurrentInstance,
} from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

// servizi
import { useRouter } from "vue-router";

// componenti standard
import Datepicker from "vue3-date-time-picker";
import { format } from "@/composables/formatDate";

// componenti
import TextInput from "../../components/components-fit/utility/inputs/TextInput.vue";
import SelectInput from "../../components/components-fit/utility/inputs/SelectInput.vue";
import ListPersone from "../../components/components-fit/utility/ListPersone.vue";
import ListComuni from "../../components/components-fit/utility/ListComuni.vue";
import ModalAddPersona from "../../components/components-fit/persone/ModalAddPersona.vue";

// gestione stato
import { useStore } from "vuex";

// richieste dati
import { checkCodFisc } from "../../requests/checkCodiceFiscale";
import { addSocieta, codiceSuggerito } from "../../requests/addSocieta";

// validazione e alert
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

// stili
import "vue3-date-time-picker/dist/main.css";
import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

export default defineComponent({
  name: "add-societa",
  components: {
    Form,
    Field,
    ErrorMessage,
    Datepicker,
    TextInput,
    SelectInput,
    ListComuni,
    ListPersone,
    ModalAddPersona,
    Loading,
  },
  setup() {
    // servizi
    const store = useStore();

    //routing
    const router = useRouter(); //usato per redirect

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    // primi eventi
    onMounted(() => {
      setCurrentPageBreadcrumbs("Aggiungi Societa", ["Lista Societa"]);
    });

    // inizializzazioni
    const inputFocus = ref("");

    // codice fiscale, salvo il vecchio codice gia validato
    const oldCodFisc = ref("");

    const data_costituzione = ref(new Date());
    const data_prima_aff = ref(new Date());

    const denominazione = ref("");

    const nome_rl = ref("");
    const id_resplegale = ref(null);

    // legali
    const selectedItaliaL = ref(false);
    const selectedProvinciaL = ref(false);
    const nazione_legale = ref("");
    const id_provincia_legale = ref(0);
    const id_comune_legale = ref(0);
    const comune_legale_txt = ref("");
    const comune_postale_txt = ref("");

    // postale
    const selectedItaliaP = ref(false);
    const selectedProvinciaP = ref(false);
    const nazione_postale = ref("");
    const id_provincia_postale = ref(0);
    const id_comune_postale = ref(0);

    //fiscale
    const selectedItaliaF = ref(false);
    const selectedProvinciaF = ref(false);
    const nazione_fiscale = ref("");
    const id_provincia_fiscale = ref(0);
    const id_comune_fiscale = ref(0);
    const comune_fiscale_txt = ref("");

    //const username = ref(null);

    // lookups - store GETTERS
    const nazioni = computed(() =>
      store.getters.getStateFromName("societa_ubicazione")
    );
    const province = computed(() =>
      store.getters.getStateFromName("istat_province")
    );
    const societaTipo = computed(() =>
      store.getters.getStateFromName("societa_tipo")
    );
    const comuniIstat = computed(() =>
      store.getters.getStateFromName("comuni_istat")
    );

    // lookups chiamate - store ACTIONS
    const keys = ref("d|su|ip|sti");
    if (
      (province.value && !province.value.length) ||
      !province.value ||
      !nazioni.value ||
      !societaTipo.value
    ) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const getComitati = () => {
      store.dispatch("setStoreListData", {
        apiLink: globalApi.LOOKUP_COMITATI,
        itemName: "comitati_list",
      });
    };
    getComitati();

    // altre store ACTIONS
    const setComuni = (comuneString, id) => {
      if (comuneString.length >= 3) {
        store.dispatch("setStoreData", {
          keys: {
            attivo: true,
            nomec: comuneString,
            codp: id,
          },
          apiLink: globalApi.COMUNI_ISTAT_LIST,
        });
      }
    };

    // store ACTIONS old style
    const getPersone = (string) => {
      if (string === "") {
        store.dispatch("svuotaPersoneNomeCognome");
        return;
      } else if (string.length < 3) return;
      store.dispatch("getPersoneNomeCognome", string);
    };
    const resetLegali = () => {
      store.dispatch("svuotaPersoneNomeCognome");
    };

    const spliceLegali = () => {
      store.dispatch("svuotaPersoneNomeCognome");
    };

    // store MUTATIONS
    const spliceComuniIstat = () => {
      store.commit("spliceArrayFromName", "comuni_istat");
    };

    // FUNZIONI nella pagina
    const changeselectedItaliaL = (e) => {
      if (e === "IT") {
        selectedItaliaL.value = true;
        selectedProvinciaL.value = false;
      } else {
        selectedItaliaL.value = false;
        id_provincia_legale.value = 0;
        id_comune_legale.value = 0;
      }
    };

    const changeselectedProvinciaL = (e) => {
      if (e === undefined || e === null || e === "-") {
        id_provincia_legale.value = null;
        selectedProvinciaL.value = false;
        id_comune_legale.value = 0;
      } else if (e != id_provincia_legale.value) {
        selectedProvinciaL.value = true;
        id_provincia_legale.value = parseInt(e);
        id_comune_legale.value = 0;
      } else {
        selectedProvinciaL.value = true;
      }
    };

    const changeselectedItaliaP = (e) => {
      if (e === "IT") {
        selectedItaliaP.value = true;
        selectedProvinciaP.value = false;
      } else {
        selectedItaliaP.value = false;
        id_provincia_postale.value = 0;
        id_comune_postale.value = 0;
      }
    };

    const changeselectedProvinciaP = (e) => {
      if (e === undefined || e === null || e === "-") {
        id_provincia_postale.value = null;
        selectedProvinciaP.value = false;
        id_comune_postale.value = 0;
      } else if (e != id_provincia_postale.value) {
        selectedProvinciaP.value = true;
        id_provincia_postale.value = parseInt(e);
        id_comune_postale.value = 0;
      } else {
        selectedProvinciaP.value = true;
      }
    };

    const changeselectedItaliaF = (e) => {
      if (e === "IT") {
        selectedItaliaF.value = true;
        selectedProvinciaF.value = false;
      } else {
        selectedItaliaF.value = false;
        id_provincia_fiscale.value = 0;
        id_comune_fiscale.value = 0;
      }
    };

    const changeselectedProvinciaF = (e) => {
      if (e === undefined || e === null || e === "-") {
        id_provincia_fiscale.value = null;
        selectedProvinciaF.value = false;
        id_comune_fiscale.value = 0;
      } else if (e != id_provincia_fiscale.value) {
        selectedProvinciaF.value = true;
        id_provincia_fiscale.value = parseInt(e);
        id_comune_fiscale.value = 0;
      } else {
        selectedProvinciaF.value = true;
      }
    };

    const suggestion = ref("");
    const presente = ref(false);
    const msgErrore = ref("");
    const CodAff = ref("");

    // FORM VALIDATION
    const schema = yup.object().shape({
      denominazione: yup.string().required("Obbligatorio"),
      cod_affiliazione: yup
        .string()
        .required("Obbligatorio")
        .nullable()
        .min(8, "Il codice di affiliazione deve avere 8 caratteri")
        .max(8, "Il codice di affiliazione deve avere 8 caratteri")
        .test(
          "codiceAffiliazioneDisponibile",
          "Il codice affiliazione non è disponibile, modificarlo in autonomia.",
          function () {
            return !presente.value;
          }
        ),
      // descrizione_breve: yup.mixed().defined(),
      sigla: yup.string(),
      id_periferico: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Obbligatorio")
        .nullable()
        .typeError("Obbligatorio"),
      cod_iva: yup.string(),
      cod_fisc: yup
        .string()
        .when("cod_iva", {
          is: (cod_iva) => cod_iva === "",
          then: yup.string().required("Obbligatorio"),
          otherwise: yup.string(),
        })
        .test(
          "checkCodFisc",
          "Inserire un codice fiscale valido",
          async function (value) {
            if (value === oldCodFisc.value) return true;
            if (value === undefined) return false;
            if (value.length === 11) {
              const isValidCodFisc = await checkCodFisc(
                value,
                globalApi.COD_FISC_SOCIETA
              );
              oldCodFisc.value = value;
              return isValidCodFisc;
            } else {
              return false;
            }
          }
        ),
      id_resplegale: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .moreThan(0, "Seleziona un rappresentante dalla lista dinamica...")
        .required("Seleziona un rappresentante dalla lista dinamica...")
        .typeError("Seleziona un rappresentante dalla lista dinamica..."),
      id_tipo: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .required("Obbligatorio")
        .nullable()
        .typeError("Obbligatorio"),
      polisportiva: yup.string(),
      email_pec: yup
        .string()
        .email("Inserire una pec valida")
        .required("Obbligatorio"),
      email: yup
        .string()
        .email("Inserire una email valida")
        .required("Obbligatorio"),
      web: yup.string(),
      skype: yup.string(),
      facebook: yup.string(),
      data_costituzione: yup.string().required("Obbligatorio").nullable(),
      data_prima_aff: yup.string().required("Obbligatorio").nullable(),
      //legale
      nazione_legale: yup
        .string()
        .required("Obbligatorio")
        .typeError("Obbligatorio"),
      id_provincia: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .nullable()
        .when("nazione_legale", {
          is: (nazione_legale) => {
            return nazione_legale === "IT";
          },
          then: yup
            .number()
            .moreThan(0, "Obbligatorio")
            .required("Obbligatorio")
            .typeError("Obbligatorio"),
        }),
      id_comune: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .nullable()
        .when("id_provincia", {
          is: (id_provincia) => {
            return id_provincia > 0;
          },
          then: yup
            .number()
            .nullable()
            .moreThan(0, "Seleziona un comune dalla lista dinamica...")
            .required("Seleziona un comune dalla lista dinamica..."),
        }),
      indirizzo: yup.string().required("Obbligatorio"),
      cap: yup.string().when("nazione_legale", {
        is: (nazione_legale) => {
          return nazione_legale === "IT";
        },
        then: yup
          .string()
          .required("Obbligatorio")
          .min(5, "Inserire un CAP valido")
          .max(5, "Inserire un CAP valido"),
        otherwise: yup.string().nullable(),
      }),
      presso: yup.string().nullable(),
      localita: yup.string(),
      mobile: yup.string(),
      telefono: yup.string().required("Obbligatorio"),
      telefono2: yup.string(),
      fax: yup.string(),
      // postale
      nazione_postale: yup
        .string()
        .required("Obbligatorio")
        .typeError("Obbligatorio"),
      id_provincia_postale: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .nullable()
        .when("nazione_postale", {
          is: (nazione_postale) => {
            return nazione_postale === "IT";
          },
          then: yup
            .number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .moreThan(0, "Obbligatorio")
            .required("Obbligatorio")
            .typeError("Obbligatorio"),
        }),
      id_comune_postale: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .nullable()
        .when("id_provincia_postale", {
          is: (id_provincia_postale) => {
            return id_provincia_postale > 0;
          },
          then: yup
            .number()
            .transform((value) => (isNaN(value) ? undefined : value))
            .nullable()
            .moreThan(0, "Seleziona un comune dalla lista dinamica...")
            .required("Seleziona un comune dalla lista dinamica..."),
        }),
      indirizzo_postale: yup.string().required("Obbligatorio"),
      cap_postale: yup.string().when("nazione_postale", {
        is: (nazione_postale) => {
          return nazione_postale === "IT";
        },
        then: yup
          .string()
          .required("Obbligatorio")
          .min(5, "Inserire un CAP valido")
          .max(5, "Inserire un CAP valido"),
        otherwise: yup.string().nullable(),
      }),
      nome_postale: yup.string().nullable(),
      localita_postale: yup.string(),
      //fiscale
      nazione_fiscale: yup.string().nullable(),
      id_provincia_fiscale: yup.number().nullable(),
      id_comune_fiscale: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .nullable(),
      //manca comune
      indirizzo_fiscale: yup.string(),
      cap_fiscale: yup.string(),
      presso_fiscale: yup.string(),
      localita_fiscale: yup.string(),
      //coordinate bancarie
      banca: yup.string(),
      agenzia: yup.string().nullable(),
      iban: yup
        .string()
        .required("Obbligatorio")
        .min(27, "inserire IBAN valido")
        .max(27, "inserire IBAN valido"),
      /*  username: yup.string().required("Obbligatorio"),
      pasw_script: yup.string().required("Obbligatorio"), */
      cessata: yup.string(),
    });

    const isLoading = ref(false);
    // invio form
    const onSubmit = (values) => {
      if (values.cod_fisc === "") {
        values = {
          ...values,
          cod_fisc: values.cod_iva,
        };
      }
      isLoading.value = true;
      addSocieta(values).then((res) => {
        isLoading.value = false;
        if (res.status == 200) {
          Swal.fire({
            html: "Società aggiunta con successo.",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          }).then(() => {
            router.push(
              `/societa/dettaglio-societa/anagrafica-societa/${res.data.id}`
            );
          });
        } else {
          Swal.fire({
            html: res.data.message
              ? res.data.message
              : "Attenzione! Si è verificato un errore, riprovare più tardi.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        }
      });
    };

    // form non valido
    const onInvalidSubmit = () => {
      let strMsg = "Compila i campi obbligatori";

      Swal.fire({
        html: strMsg,
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
        },
      });
    };
    const loadingCodice = ref(false);
    const getCodAffSugg = (id) => {
      presente.value = false;
      suggestion.value = null;
      CodAff.value = null;
      msgErrore.value = "";
      loadingCodice.value = true;
      codiceSuggerito(id).then((res) => {
        loadingCodice.value = false;

        if (res.status != 200 && id) {
          msgErrore.value =
            " Non è stato possibile generare il codice affiliazione, modificarlo in autonomia.";
          return;
        }
        presente.value = res.data.results[0].presente;
        suggestion.value = res.data.results[0].cod_affiliazione;
        CodAff.value = suggestion.value;
      });
    };

    // page bindings
    return {
      // campi e variabili pagina
      loadingCodice,
      msgErrore,
      presente,
      suggestion,
      getCodAffSugg,
      CodAff,
      inputFocus,
      schema,
      denominazione,
      data_costituzione,
      data_prima_aff,
      nome_rl,
      id_resplegale,
      comune_legale_txt,
      comune_postale_txt,
      comune_fiscale_txt,
      nazione_legale,
      nazione_fiscale,
      nazione_postale,
      id_provincia_legale,
      id_provincia_fiscale,
      id_provincia_postale,
      id_comune_legale,
      id_comune_fiscale,
      id_comune_postale,
      polisportiva: [
        { id: "1", label: "SI" },
        { id: "0", label: "NO" },
      ],
      //username,
      selectedItaliaL,
      selectedProvinciaL,
      selectedItaliaP,
      selectedProvinciaP,
      selectedItaliaF,
      selectedProvinciaF,
      // liste
      nazioni,
      province,
      comuniIstat,
      societaTipo,
      // liste old style
      legali: computed(() => store.getters.personeSocieta),
      // funzionalità
      format,
      getPersone,
      resetLegali,
      spliceLegali,
      setComuni,
      spliceComuniIstat,
      changeselectedItaliaL,
      changeselectedProvinciaL,
      changeselectedItaliaP,
      changeselectedProvinciaP,
      changeselectedItaliaF,
      changeselectedProvinciaF,
      onSubmit,
      onInvalidSubmit,
      isLoading,
      comitati: computed(() =>
        store.getters.getStateFromName("resultscomitati_list")
      ),
    };
  },
});
</script>
<style scoped>
:deep(.popper) {
  background: #b7bcd0 !important;
  padding: 5px !important;
  border-radius: 5px !important;
  color: #303133 !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  font-size: 11px !important;
}

:deep(.popper #arrow::before) {
  background: #b7bcd0 !important;
  left: 0px !important;
}

:deep(.popper:hover),
:deep(.popper:hover > #arrow::before) {
  background: #b7bcd0 !important;
  left: 0px !important;
}
</style>
