import services from "../axios/dbManag";

export const addSocieta = (societa) => {
  return services.dbManag
    .post("/anagrafiche/societa/add", {
      ...societa,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const addEnte = (ente) => {
  return services.dbManag
    .post("/anagrafiche/enti-aggregati/add", {
      ...ente,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const codiceSuggerito = (id_Comitato) => {
  return services.dbManag
    .post("/anagrafiche/societa/codice-suggerito", {
      id_Comitato,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};

export const codiceSuggeritoEnte = (id_Comitato) => {
  return services.dbManag
    .post("/anagrafiche/enti-aggregati/codice-suggerito", {
      id_Comitato,
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e.response;
    });
};
